import React from 'react';

class Features extends React.Component {
  render() {
    return (
      <div className="app-features text-center" id="features">
        <div className="container">
          <h1 className="wow fadeInDown" data-wow-delay="0.1s">Features and Details</h1>
          <p className="wow fadeInDown" data-wow-delay="0.2s">
            We're constantly adding new features. Be sure to check back with us <br className="hidden-xs" /> from time
            to time to see what's new!
          </p>
          <div className="col-md-4 features-left">
            <div className="col-md-12 wow fadeInDown" data-wow-delay="0.2s">
              <div className="icon">
                <i className="ion-pie-graph"></i>
              </div>
              <div className="feature-single">
                <h1>Track by Macros</h1>
                <p>
                  Our app helps you track your food by macro breakdown, not just by calories.
                </p>
              </div>
            </div>
            <div className="col-md-12 wow fadeInDown" data-wow-delay="0.3s">
              <div className="icon">
                <i className="ion-fork"></i>
              </div>
              <div className="feature-single">
                <h1>Huge Food Database</h1>
                <p>
                  Search a huge database of foods, both by brand and generic.
                </p>
              </div>
            </div>
            <div className="col-md-12 wow fadeInDown" data-wow-delay="0.4s">
              <div className="icon">
                <i className="ion-flash"></i>
              </div>
              <div className="feature-single">
                <h1>Quickly Log Your Favorite Foods</h1>
                <p>
                  When you log foods frequently, they become available without searching.
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-4 wow fadeInDown" data-wow-delay="0.5s">
            <div id="carousel-features" className="carousel slide" data-ride="carousel">
              <ol className="carousel-indicators">
                <li data-target="#carousel-features" data-slide-to="0" className="active"></li>
                <li data-target="#carousel-features" data-slide-to="1"></li>
                <li data-target="#carousel-features" data-slide-to="2"></li>
              </ol>

              <div className="carousel-inner" role="listbox">
                <div className="item active">
                  <img className="img-responsive" src="/assets/images/hero.png" alt="App" />
                  <div className="carousel-caption">
                  </div>
                </div>
                <div className="item">
                  <img className="img-responsive" src="/assets/images/search.png" alt="Search Feature" />
                  <div className="carousel-caption">
                  </div>
                </div>
                <div className="item">
                  <img className="img-responsive" src="/assets/images/targets.png" alt="Macro Targets Feature" />
                  <div className="carousel-caption">
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4 features-left">
          <div className="col-md-12 wow fadeInDown" data-wow-delay="0.8s">
              <div className="icon">
                <i className="ion-android-restaurant"></i>
              </div>
              <div className="feature-single">
                <h1>Save Your Meals</h1>
                <p>
                  Repeat the same meal over and over? You can save it for later.
                </p>
              </div>
            </div>
            <div className="col-md-12 wow fadeInDown" data-wow-delay="0.7s">
              <div className="icon">
                <i className="ion-pizza"></i>
              </div>
              <div className="feature-single">
                <h1>Add Custom Foods</h1>
                <p>
                  Can't find a food in our database? Add your own!
                </p>
              </div>
            </div>
            <div className="col-md-12 wow fadeInDown" data-wow-delay="0.6s">
              <div className="icon">
                <i className="ion-stats-bars"></i>
              </div>
              <div className="feature-single">
                <h1>Track Your Goals</h1>
                <p>
                  Track your progress by several factors.<br />(Feature coming soon)
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export { Features };
