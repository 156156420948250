import React from 'react';
import { Navbar } from './components/Navbar';
import { Hero } from './components/Hero';
// import { Client } from './components/Client';
import { Pitch } from './components/Pitch';
import { Features } from './components/Features';
// import { FeatureSub } from './components/FeatureSub';
// import { SplitFeatures } from './components/SplitFeatures';
// import { Reviews } from './components/Reviews';
// import { Pricing } from './components/Pricing';
import { CallToAction } from './components/CallToAction';
import { Footer } from './components/Footer';

class App extends React.Component {
  render() {
    return (
      <div className="wrapper">
        <Navbar />
        <div className="main app form" id="main">
          <Hero />
          { /* <Client /> */ }
          <Pitch />
          <Features />
          { /* <FeatureSub /> */ }
          { /* <SplitFeatures /> */ }
          { /* <Reviews /> */ }
          { /* <Pricing /> */ }
          <CallToAction />
          <Footer />
          <a id="back-top" className="back-to-top page-scroll" href="#main">
            <i className="ion-ios-arrow-thin-up"></i>
          </a>
        </div>
      </div>
    );
  }
}

export default App;
