import React from 'react';
import { SignupButton } from './SignupButton';

const signUpImageStyle = {
  width: "300px"
};

class Hero extends React.Component {
  render() {
    return (
      <div className="image-bg">
      <div className="hero-section">
        <div className="container nopadding">
          <div className="col-md-5">
            { /* <img className="img-responsive wow fadeInUp" data-wow-delay="0.1s" src="/assets/images/app-signup.png" alt="App" /> */ }
            <img className="img-responsive wow fadeInUp" style={ signUpImageStyle } data-wow-delay="0.1s" src="/assets/images/hero.png" alt="App" />
          </div>
          <div className="col-md-7">
            <div className="hero-content">
              <h1 className="wow fadeInUp" data-wow-delay="0.1s">Track Your Macros</h1>
              <p className="wow fadeInUp" data-wow-delay="0.2s">
                Get in shape while enjoying what you eat! Our app will help you stay on track with your macronutrition.
              </p>
              <SignupButton />
            </div>
          </div>
        </div>
      </div>
      </div>
    );
  }
}

export { Hero };
