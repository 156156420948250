import React from 'react';
import { SignupButton } from './SignupButton';

class CallToAction extends React.Component {
  render() {
    return (
      <div className="cta-sub no-color" id="signup">
        <div className="container">
          <div className="cta-inner">
            <h1 className="wow fadeInUp" data-wow-delay="0s">Sign up today and try it out!</h1>
            <p className="wow fadeInUp" data-wow-delay="0.2s">
              Track your macronutrition and stay on top of your goals.
            </p>
            <SignupButton />
          </div>
        </div>
      </div>
    );
  }
}

export { CallToAction };
