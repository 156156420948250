import React from 'react';

class Navbar extends React.Component {
  render() {
    return (
      <div className="container">
        <nav className="navbar navbar-default navbar-fixed-top">
          <div className="container">

            <div className="navbar-header page-scroll">
              <button type="button" className="navbar-toggle" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1" aria-expanded="false">
                <span className="sr-only">Toggle navigation</span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
              </button>
              <a className="navbar-brand page-scroll nav-white" href="#main">Within Your Macros</a>
            </div>

            <div className="collapse navbar-collapse navbar-right" id="bs-example-navbar-collapse-1">
              <ul className="nav navbar-nav nav-white">
                  <li><a className="page-scroll" href="#main">Product</a></li>
                  <li><a className="page-scroll" href="#features">Features</a></li>
                  <li><a className="page-scroll" href="#signup">Sign Up</a></li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    );
  }
}

export { Navbar };
