import React from 'react';

class Footer extends React.Component {
  render() {
    return (
      <div className="footer">
        <div className="container">
          <div className="col-md-6">
              <h3>Within Your Macros</h3>
              <p>
                A food journal web app designed for people <br className="hidden-xs" /> following IIFYM
                (if it fits your macros).
              </p>
            <div className="footer-text">
              <p>
                Copyright © 2020 Within Your Macros, LLC. All Rights Reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export { Footer };
