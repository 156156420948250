import React from 'react';

class Pitch extends React.Component {
  render() {
    return (
      <div className="pitch text-center">
        <div className="container">
          <div className="pitch-intro">
            <h1 className="wow fadeInDown" data-wow-delay="0.2s">Why Choose Within Your Macros ?</h1>
            <p className="wow fadeInDown" data-wow-delay="0.2s">
              We make it easy to track your macros to help you get in shape. Save meals,
              track your goals, and more!
            </p>
          </div>
          <div className="col-md-12">
            <div className="col-md-4 wow fadeInDown" data-wow-delay="0.2s">
              <div className="pitch-icon">
                <i className="ion-edit"></i>
              </div>
              <div className="pitch-content">
                <h1>Save Your Meals</h1>
                <p>
                  Have a common set of foods you log often? Save it as a meal so you can log your foods faster.
                </p>
              </div>
            </div>
            <div className="col-md-4 wow fadeInDown" data-wow-delay="0.2s">
              <div className="pitch-icon">
                <i className="ion-pie-graph"></i>
              </div>
              <div className="pitch-content">
                <h1>Watch Your Macros</h1>
                <p>
                  See macro breakdown at every level. Per food, per meal, per day.
                </p>
              </div>
            </div>
            <div className="col-md-4 wow fadeInDown" data-wow-delay="0.2s">
              <div className="pitch-icon">
                <i className="ion-stats-bars"></i>
              </div>
              <div className="pitch-content">
                <h1>Track Your Progress</h1>
                <p>
                  Keep track of your goals by setting your target macros.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export { Pitch };
