import React from 'react';

const appUrl = process.env.REACT_APP_SIGNUP_PAGE;

class SignupButton extends React.Component {
  render() {
    return (
      <div className="form wow fadeInUp" data-wow-delay="0.3s">
        <a className="btn btn-lg btn-action" role="button" href={appUrl}>Sign up / Login</a>
      </div>
    );
  }
}

export { SignupButton };
